<template>
  <main class="highlight-transparent">
    <div class="main-container d-flex flex-column">
      <MainHeader class="header"/>
      <div class="main-content">
        <router-view />
      </div>
      <MainFooter />
    </div>
  </main>
</template>

<script src="./main-container.js"></script>

<style lang="scss" scoped>
  .main-container {
    background: linear-gradient(180deg, #071744 0%, #1853F6 100%);
  }
</style>
