<template>
  <div class="main-footer footer-background">
    <div class="container">
      <div class="content">
        <div
          :class="[
            'd-flex justify-space-between align-center',
            windowWidth <= parseInt(breakpoints.xs) ? 'flex-column align-center' : ''
          ]"
        >
          <VImg
            class="pointer"
            src="unitedMetasLogo.svg"
            width="132"
            height="48"
            @click="scrollToTop"
          />
          <div>
            <div class="text-block d-flex flex-column">
              <router-link
                :to="{ name: 'PrivacyPolicy' }"
                class="text-body-10 white--text opacity-80 cursor-pointer mb-2 text-underline"
              >
                Privacy Policy
              </router-link>
              <router-link
                :to="{ name: 'TermsAndConditions' }"
                class="text-body-10 white--text opacity-80 cursor-pointer text-underline"
              >
                Terms and Conditions
              </router-link>
            </div>
          </div>
          <div>
            <div class="text-block d-flex flex-column align-end">
              <span class="text-body-10 white--text opacity-80 cursor-pointer">
                United Metas DMCC
              </span>
              <span class="text-body-10 white--text opacity-80 cursor-pointer">
                Unit No: 801
              </span>
              <span class="text-body-10 white--text opacity-80 cursor-pointer">
                Jumeirah Lakes Towers, Dubai, UAE
              </span>
              <span class="font-humans text-body-6 mt-4">
                e-mail: <a href="mailto: support@unitedmetas.io">support@unitedmetas.io</a>
              </span>
              <span class="font-humans text-body-6 mt-4">
                {{ $t('footer_copyright') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./main-footer.js"></script>

<style lang="scss" scoped>
.main-footer {
  padding-top: 18px;
  padding-bottom: 20px;
}
.text-body-6 {
  line-height: 19px;
  color: rgba($color: $white, $alpha: 0.5);
}
@media only screen and (max-width: $xs) {
  .main-footer {
    margin-top: 120px;
    padding-top: 50px;
    .v-image {
      margin-bottom: 30px;
    }
  }
}
</style>
