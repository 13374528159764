import StorageHelper from '@/helpers/localStorageHelper.js'
import { useI18n } from "vue3-i18n"

export default {
  name: 'LanguagesComponent',
  props: {
    isSiteTop: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    let i18n = useI18n()
    let setLocale = (lang) => {
      i18n.setLocale(lang)
    }
    return {
      setLocale
    }
  },
  data: () => ({
    LOCAL_STORAGE_KEY: 'united_metas_locale',
    currentLanguage: null,
    languageList: ['en', 'es', 'ko', 'zh', 'th', 'ja', 'pl', 'ru', 'vi'],
    isHiddenDropdownMenu: true,
  }),
  created () {
    this.currentLanguage = StorageHelper.get(this.LOCAL_STORAGE_KEY) || 'en'
  },
  computed: {
    filteredLanguageList () {
      return this.languageList.filter(item => item !== this.currentLanguage)
    }
  },
  methods: {
    formatLocale (locale) {
      return locale.toUpperCase()
    },
    hideDropdownMenu () {
      if (!this.isHiddenDropdownMenu) {
        this.isHiddenDropdownMenu = true
      }
    },
    changeCurrentLanguage (language) {
      this.currentLanguage = language
      StorageHelper.set(this.LOCAL_STORAGE_KEY, language)
      this.isHiddenDropdownMenu = true
    },
    start (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end (el) {
      el.style.height = null
    }
  },
  watch: {
    currentLanguage (value) {
      this.setLocale(value)
    }
  }
}