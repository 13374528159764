<template>
  <div class="languages" v-click-outside="hideDropdownMenu">
    <div
      class="language-item pointer d-flex align-center justify-center"
      @click="isHiddenDropdownMenu = !isHiddenDropdownMenu"
    >
      <VImg
        class="mr-2"
        src="languagesIcon.svg"
        width="20"
        height="20"
      />
      <span class="text-body-6 white--text font-humans">
        {{ formatLocale(currentLanguage) }}
      </span>
      <VImg
        v-if="isMobile"
        :class="isHiddenDropdownMenu ? 'arrow-down' : 'arrow-up'"
        src="dropdownMenuArrow.svg"
        width="6"
        height="4"
      />
    </div>
    <div
      v-if="!isMobile && !isHiddenDropdownMenu"
      :class="[
        'divider absolute',
        isSiteTop ? 'light-blue' : 'white'
      ]"
    />
    <Transition
      name="dropdown"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-if="!isHiddenDropdownMenu" class="language-list white">
        <div
          class="language-item pointer text-center"
          v-for="(language, languageIndex) in filteredLanguageList"
          :key="languageIndex"
          @click="changeCurrentLanguage(language)"
        >
          <span class="d-block text-body-6 black-text text-center font-humans">
            {{ formatLocale(language) }}
          </span>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script src="./languages-component.js"></script>

<style lang="scss" scoped>
.languages {
  position: relative;
  z-index: 20;
  width: 52px;
}
.divider {
  position: absolute;
  height: 2px;
  width: 100%;
  left: 50%;
  top: 36px;
  transform: translateX(-50%);
  border-radius: 2px;
  transition: 0.2s linear;
}
.language-list {
  overflow: hidden;
  position: absolute;
  top: calc(100% + 27px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-radius: 10px;
  box-shadow:  0px 2px 10px 0px rgba($color: black, $alpha: 0.15);
  .language-item {
    padding: 5px 0px;
    width: 100%;
    transition: 0.2s linear;
    span {
      line-height: 14px;
    }
    &:first-of-type {
      padding-top: 10px;
    }
    &:last-of-type {
      padding-bottom: 10px;
    }
    &:hover {
      background-color: rgba($color: $lightBlue, $alpha: 0.5);
    }
  }
}
.arrow-down {
  transform: rotate(180deg);
  margin-left: 6px;
  transition: 0.2s linear;
}
.arrow-up {
  margin-left: 6px;
  transition: 0.2s linear;
}

.dropdown-enter-active,
.dropdown-leave-active {
  overflow: hidden;
  transition: all 0.2s ease-in;
}
.dropdown-enter-from,
.dropdown-leave-to {
  height: 0 !important;
}
@media only screen and (max-width: $sm) {
  .languages {
    width: 76px;
  }
  .language-list {
    top: calc(100% + 16px);
    left: 50%;
  }
}
</style>