import MainHeader from '@/components/main-header/MainHeader.vue'
import MainFooter from '@/components/main-footer/MainFooter.vue'

export default {
  name: 'MainContainer',
  components: {
    MainHeader,
    MainFooter
  }
}
