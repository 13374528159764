<template>
  <div v-if="pageYOffset >= 0"
    :class="[
      'main-header',
      { 'scrolled': pageYOffset }
    ]"
  >
    <div class="container">
      <div class="content">
        <div class="row align-center justify-space-between">
          <router-link :to="{ name: 'Home' }"
            class="logo-link"
          >
            <VImg
              v-if="isMobile"
              class="pointer relative menu-icon"
              :src="isOpenMobileMenu ? 'iconCross.svg' : 'iconBurger.svg'"
              width="30"
              :height="isOpenMobileMenu ? '22' : '8'"
              @click="isOpenMobileMenu = !isOpenMobileMenu"
            />
            <div
              v-if="!isMobile"
              class="relative pointer"
              @click="scrollToSection('preview')"
            >
              <VImg class="logo-top"
                src="logoTop.svg"
                width="63"
                :height="pageYOffset ? '0' : '37'"
              />
                <VImg
                class="logo-bottom"
                src="logoBottom.svg"
                width="177"
                height="26"
              />
            </div>
            <VImg
              v-else
              class="mobile-logo pointer"
              src="unitedMetasLogo.svg"
              width="116"
              height="42"
              @click="scrollToSection('preview')"
            />
          </router-link>
          <div class="d-flex align-center">
            <div v-if="!isMobile" class="nav-bar d-flex mr-25">
              <a
                class="nav-link white--text text-body-6 font-humans pointer relative"
                v-for="navLink in navLinks"
                :key="navLink.anchor"
                @click="scrollToSection(navLink.anchor)"
              >
                {{ $t(navLink.name) }}
                <div
                  :class="[
                    'divider absolute',
                    pageYOffset ? 'white' : 'light-blue',
                    navLink.anchor === currentSection ? 'divider-active ' : 'divider-hidden'
                  ]"
                />
              </a>
              <a
                class="nav-link white--text text-body-6 font-humans pointer relative"
                href="https://metavie.io/"
                target="_blank"
              >
                Metavie
                <div
                  :class="[
                    'divider absolute',
                    pageYOffset ? 'white' : 'light-blue',
                    'divider-hidden'
                  ]"
                />
              </a>
            </div>
            <div class="d-flex align-center">
              <LanguagesComponent
                v-if="!isMobile"
                :isSiteTop="pageYOffset ? false : true"
              />
              <div @click="openLink('https://t.me/unitedmetas')"
                :class="[
                  'icon-wrapper circle relative pointer',
                  { 'ml-5': !isMobile }
                ]"
              >
                <i class="icon-telegram absolute" />
              </div>
            </div>
          </div>
          <div
            @wheel.prevent
            v-if="isMobile"
            :class="[
              'mobile-menu absolute dark',
              isOpenMobileMenu ? 'opened-menu' : 'closed-menu'
            ]"
          >
            <div class="nav-bar d-flex flex-column align-center">
              <a
                :class="[
                  'nav-link font-humans pointer',
                  navLink.anchor === currentSection ? 'light-blue--text ' : 'white--text'
                ]"
                v-for="navLink in navLinks"
                :key="navLink.anchor"
                @click="scrollToSection(navLink.anchor)"
              >
                {{ $t(navLink.name) }}
              </a>
            </div>
            <LanguagesComponent class="mobile-languages"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./main-header.js"></script>

<style lang="scss" scoped>
.main-header {
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  width: 100%;
  z-index: 21;
  transition: 0.2s linear;
}
.scrolled {
  background: rgba($color: $darkBlue, $alpha: 0.9);
}
.logo-top {
  position: relative;
  transition: 0.2s linear;
  top: 4px;
  left: 5px;
}
.nav-bar {
  gap: 60px;
}
.nav-link {
  transition: 0.2s linear;
  line-height: 19px;
}
.divider {
  transition: 0.2s linear;
  height: 2px;
  left: 0%;
  top: 36px;
}
.nav-link:hover .divider {
  width: 100%;
}
.divider-hidden {
  width: 0;
}
.divider-active {
  width: 100%;
}
.icon-wrapper {
  width: 54px;
  height: 54px;
  background-color: rgba($color: $lightBlue, $alpha: 0.5);
  transition: 0.2s linear;
}
.icon-telegram {
  width: 27px;
  font-size: 22px;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s linear;
  &::before {
    margin: 0;
  }
}
.icon-wrapper:hover {
  background-color: white;
  .icon-telegram {
    color: $darkBlue;
  }
}
.menu-icon,
.mobile-logo {
  position: relative;
  z-index: 5;
}
.mobile-menu {
  width: 100%;
  left: 0;
  top: 0%;
  z-index: 4;
  overflow: hidden;
  transition: 0.3s ease-in;
}
.opened-menu {
  height: 100vh;
  visibility: visible;
}
.closed-menu {
  height: 0px;
  visibility: hidden;
}

@media only screen and (max-width: $sm) {
  .mobile-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .scrolled {
    background: rgba($color: $dark, $alpha: 0.9);
  }
  .icon-wrapper {
    z-index: 3;
    width: 42px;
    height: 42px;
  }
  .icon-telegram {
    width: 21px;
    font-size: 17px;
  }
  .nav-bar {
    margin-top: 118px;
    gap: 30px;
    .nav-link {
      font-size: 22px;
      line-height: 26px;
      &:hover {
        color: $lightBlue;
      }
    }
  }
  .nav-link:hover::before {
    width: 0%;
  }
  .mobile-languages {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 259px;
  }
}
</style>
