import LanguagesComponent from './languages/LanguagesComponent.vue'

export default {
  name: 'MainHeader',
  components: {
    LanguagesComponent
  },
  data: () => ({
    pageYOffset: -1,
    isOpenMobileMenu: false,
    currentSection: null,
    navLinks: [
      {
        name: 'header_navLinks_unitedmetas',
        anchor: 'unitedMetas'
      },
      {
        name: 'header_navLinks_mission',
        anchor: 'mission'
      },
      {
        name: 'header_navLinks_phase',
        anchor: 'phases'
      },
      {
        name: 'header_navLinks_roadmap',
        anchor: 'roadMap'
      }
    ]
  }),
  mounted () {
    if (!window.pageYOffset) {
      this.pageYOffset = 0
    }
    let anchorItems = document.getElementsByClassName('menu-anchor')
    anchorItems = Array.from(anchorItems)
    window.addEventListener('scroll', () => {
      this.pageYOffset = window.pageYOffset

      anchorItems.map((anchorItem) => {
        if (window.pageYOffset > (anchorItem.offsetTop - 200)) {
          this.currentSection = anchorItem.id
        }
      })
    })
  },
  methods: {
    openLink (link) {
      window.open(link)
    },
    scrollToSection (id) {
      this.$router.push({ name: 'Home' })
      let section = document.getElementById(id)
      if (!this.isMobile) {
        if (section) {
          section.scrollIntoView({ block:'start', behavior:'smooth' })
        }
      } else {
        this.isOpenMobileMenu = false
        if (section) {
          section.scrollIntoView({ block:'start', behavior:'smooth' })
        }
      }
    }
  }
}
